.signup-container {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
}

.signup-left {
    background: url(https://planethiring.com/assets/img/banner-image.png);
    background-position: center;
    flex: 2;
    display: flex;
}

.signup-left-content {
    background-color: rgba(0, 0, 0, 0.4);
    flex: 2;
    position: relative;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    padding: 20px;
}
.signup-left h3 {
    color: rgb(206, 206, 206);
    font-size: 2rem;
    margin-bottom: 1.25rem !important;
}

.signup-left p {
    color: #fff;
    opacity: 0.8 !important;
    font-size: 12px;
    font-weight: lighter !important;
    margin: 6px 0px;
}

.left-footer {
    position: absolute;
    bottom: 25px;
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.left-footer p {
    font-size: 16px;
}

.left-footer div a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
}

.left-footer div {
    display: flex;
    gap: 30px;
}

.signup-right {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.signup-form {
    box-shadow: 0 5px 30px 0 rgb(214 215 216 / 57%);
    padding: 20px;
    width: 600px;
    margin-top: 30px;
}
.signup-form h3 {
    font-size: 30px;
    font-weight: 500;
    margin: 20px 0px 30px 0px;
}

.inputs-group {
    display: flex;
    gap: 20px;
    margin-bottom: 5px;
}

.inputs-group input,
.inputs-group .MuiFormControl-root {
    flex: 1;
    border: none;
    outline: none;
    width: 100%;
    /* border-bottom: 1px solid rgb(214 215 216); */
}
.inputs-group label {
    font-size: 14px;
}

.signup-form .ack-label {
    display: flex;
    align-items: start;
    gap: 10px;
    cursor: pointer;
}
.signup-form .ack-label input {
    margin-top: 5px;
    font-size: 30px;
    height: 20px;
    width: 20px;
}

.inputs-group .input-error {
    color: rgb(243, 126, 126);
    font-size: 14px;
}
.inputs-group div {
    flex: 1;
}

.signup-form .btn-cont {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
}

.signup-form .btn-cont button:disabled {
    background-color: rgb(93, 93, 138);
    color: #bebebe;
    cursor: not-allowed;
}

@media screen and (max-width: 992px) {
    .signup-container {
        flex-direction: column;
    }
    .signup-left {
        position: static;
        flex: auto;
        padding: 10px 20px;
        background-position: top center;
    }
    .left-footer {
        position: static;
    }

    .left-footer p,
    .left-footer div a {
        color: #fff;
        font-size: 14px;
    }
    .signup-right {
        flex: 4;
        height: 60vh !important;
    }

    .inputs-group {
        flex-direction: column;
    }

    .inputs-group input {
        width: 100%;
    }
}
