* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.container {
  max-width: 900px !important;
  padding: 0px 20px;
}

.container-sm {
  max-width: 900px;
  padding: 0px 20px;
}

.checkCam {
  border-radius: 60px;
  padding: 10px 25px;
}

a {
  text-decoration: none;
}

.quiz-container {
  border-left: 2px solid #f4f4f4;
  padding: 20px;
}
.quiz-row {
  display: flex;
  justify-content: space-between;
}

.cam-container {
  background-color: gray;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.check-box {
  width: 35px;
  height: 35px;
  padding: 10px;
  font-size: 30px;
  margin-right: 10px;
}
.is-recording {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(180, 180, 180);
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.is-recording.active::before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: blur(5px);
  background-color: red;
}

.is-recording.active{
  background-color: red;
}

.file-upload{
  border: 4px dotted #f4f4f4;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  color: gray;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  letter-spacing: 1.5px;
  font-family: 'Courier New', Courier, monospace;
}